<template>
  <div class="blog main_top">
    <div class="blog_header flex">
        <div class="blog_tabs flex " v-for="(item,index) in tabs" :key="index" @click="getBlog(item.id)"> 
            <div class="tab_item " :class="[tabId==item.id?'is_active':'']">{{ item.tranName }}</div>
        </div>
    </div>
    <div class="blog_container">
        <div class="blog_thumb">
            <div class="blog_group flex" v-for="(item,index) in blogList" :key="index" @click="toPage('/blogDetails',item.noId,item.key2)">
                <div class="blog_img"><img :src="item.image" :alt="item.key1"></div>
                <div class="blog_info flex_col">
                    <div class="blog_title">{{ item.title }}</div>
                    <div class="blog_msg" v-html="item.remark"></div>
                    <div class="blog_time">{{ item.createTime }}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { sortBy,processString} from '../../utils/tool'
export default {
    data(){
        return{
            tabs:[
                {tranName:'All',id:'0'},
            ],
            tabId:'0',
            pageIndex:1,
            pageSize:10,
            isLastPage:false,
            blogList:[],
        
        }
    },
    created(){
        this.getBlogClass()
       console.log('$route',this.$route)
    },
    methods:{
        
        getBlogClass(){
            let lang =this.$store.state.lang
            let _this = this
            if(!lang){   
                setTimeout(()=>{
                _this.getBlogClass() 
                },1000)
                return
            }
            this.getBlogList()
            this.$axiosApi.getBlogClass().then(res=>{
                if(res.code == 200){
                    let tabs = this.tabs
                    res.data.map(v=>{
                        if(!/^\s*$/.test(v.allName)){ 
                            v.allName = JSON.parse(v.allName)
                            v.allName.map(c=>{
                            if(c.language ==lang.dictValue ){
                                v.tranName = c.name
                            }
                           })
                        }

                        this.tabs.push(v)
                        return v
                    })
                    this.tabs.sort(sortBy('sortDesc',1))
                  
                    console.log('sortTabs,sortTabs',this.tabs)
                }
            })
        },
        getBlog(id){
           this.tabId = id
           this.blogList = []
           this.getBlogList()
        },
        getBlogList(){
            let classId = this.tabId
            let pageIndex = this.pageIndex
            let pageSize = this.pageSize
            let language =this.$store.state.lang.dictValue 
            this.$axiosApi.getBlogList({pageIndex,pageSize,classId,language}).then(res=>{
                if(res.code == 200){
                    let blogList = res.data.list.map(v=>{   
                        this.blogList.push(v)
                        return v
                    })
                    this.isLastPage = res.data.isLastPage
                }
            })
        },
        toPage(path,id,title){
            let lang = localStorage.getItem('lang')
            let blogTitle = ''
            blogTitle = processString(title) 
            this.$router.push(path+'/'+lang+'/'+id+'/'+blogTitle)
        },
        handleScroll() {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const bottomOfPage = windowHeight + scrollY >= documentHeight;   
            if (bottomOfPage && !this.isLastPage) {
                this.pageIndex = this.pageIndex+1
                this.getBlogList()
            }         
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)  
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    
}
</script>

<style lang="scss" scoped>
.blog{
    position: relative;
    .blog_header{
        margin-top:16px;
        background:#fff;
        padding:0 32px;
        border-radius: 4px;

        .blog_tabs{
         cursor: pointer;
            .tab_item{
                height: 48px;
                line-height: 48px;
                margin-right: 36px;
                font-weight: 400;
                color: #797a7e;
                white-space: nowrap;
                position: relative;
                font-size:16px;
                &:hover{
                    opacity: 0.7;
                }
            }
        }
        .is_active{
            position: relative;
            &::before{
                border-radius: 4px;
                content: "";
                position: absolute;
                width: 24px;
                height: 4px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: #FFCB2E;
            }
        }
    }
    .blog_container{
        
        width: 100%;
        .blog_thumb{
            width: 100%;
            .blog_group{
                margin-top:16px;
                padding:16px;
                background:#fff;
                border-radius: 4px;
                cursor: pointer;
                &:hover{
                    opacity: 0.7;
                }
            }
            .blog_img{
                width: 210px;
                height: 140px;
                text-align: center;
                background-color: #F7F8FC;
                overflow: hidden;
                display: flex;
                justify-content: center;
                border-radius: 4px;
            }
            .blog_info{
                margin-left:16px;
                text-align: left;
                .blog_title{
                    font-size: 24px;
                    line-height: 32px;
                    color: #1f2128e6;
                }
                .blog_msg{
                    font-size: 15px;
                    line-height: 23px;
                    color: rgba(31, 33, 40, .6);
                }
                .blog_time{
                    font-size: 15px;
                    margin-top: auto;
                    color: rgba(31, 33, 40, .4);
                    line-height: 22px;
                }
            }
        }
    }
}
@media screen and (max-width:768px){
    .blog{
        margin:0 -16px;
    }
    .blog_header{
        margin:0!important;
        border-radius: 0!important;
        position: fixed;
        top:48px;
        left:0;
        right:0;
        z-index: 99;
    
    }
    .blog_container{
       
        width: auto!important;
        margin-top:52px!important;
        .blog_group{
            margin:0!important;
        }
    }
}
</style>